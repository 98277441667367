
import api from '@/api/co.api'

const Names = {
  LASTEST: 'lastest',
  PARK: 'park',
  MAJOR_PROJECT: 'major_project',
  UNDERSTAND: 'understand'
}

const CatLastest = {
  title: '公开信息',
  name: Names.LASTEST,
  href: '/disclose/list',
  topicId: '',
  type: api.comm.NoticeTypes.PUBLICS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatPark = {
  title: '园区文件',
  name: Names.PARK,
  href: '/disclose/list',
  topicId: '',
  type: api.comm.NoticeTypes.PARKS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatMajorProject = {
  title: '重大项目',
  name: Names.MAJOR_PROJECT,
  href: '/disclose/list',
  topicId: '',
  type: api.comm.NoticeTypes.PROJECTS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const CatUnderstand = {
  title: '政策解读',
  name: Names.UNDERSTAND,
  href: '/disclose/list',
  topicId: '',
  type: api.comm.NoticeTypes.EXPLAINS,
  navigated: false,
  params: null,
  items: [],
  latest: [],
  pagination: {
    current: 1,
    length: 15,
    totalVisible: 7
  },
  buttons: [{
    icon: 'mdi-chevron-right'
  }]
}

const disclosePreset = {
  DetailName: api.page.discloseDetail.name,
  ListName: api.page.discloseList.name,
  sideLimit: '5',
  Names,
  Parts: {
    [Names.LASTEST]: CatLastest,
    [Names.PARK]: CatPark,
    [Names.MAJOR_PROJECT]: CatMajorProject,
    [Names.UNDERSTAND]: CatUnderstand
  }
}

export default disclosePreset
